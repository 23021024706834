<template>
  <sheet v-if="obInvoice" dense depressed light outlined>
    <v-row class="align-center">
      <v-col
        v-show="!hideMovementTypeSelect"
        key="invoice_movement_type_id"
        :lg="signed ? 2 : 4"
        :sm="signed ? 3 : 4"
        cols="12"
        md="2"
      >
        <invoice-movement-type-select
          :disabled="readOnly"
          :invoice-group="obInvoiceGroup"
          :movement-type-code="arMovementTypeCode"
          :value="iMovementType"
          validate-company
          @change="onSelectMovementType"
        />
      </v-col>

      <v-col
        v-if="signed && cfeName"
        key="title"
        cols="12"
        lg="5"
        md="4"
        sm="4"
      >
        <div class="d-flex align-center">
          <div
            class="text-subtitle-2 font-weight-medium"
            v-text="$t('invoice.type')"
          />
          <div class="ml-8 text-subtitle-1 font-weight-bold">
            {{ cfeName }} -
            {{ $t(obInvoice.is_cash ? "cash" : "credit") }}
          </div>
        </div>
      </v-col>

      <v-col
        v-if="showIsCash"
        key="is_cash"
        class="text-center"
        cols="12"
        lg="3"
        md="3"
        sm="4"
      >
        <btn-cash-credit-toggle
          v-model="obInvoice.is_cash"
          :read-only="readOnly"
          @change="onSetPriceWithTax"
        />
      </v-col>

      <v-col
        v-if="!isRemito && !signed"
        key="is_contingency"
        cols="12"
        md="2"
        sm="4"
      >
        <v-switch
          v-model="obInvoice.is_contingency"
          :disabled="readOnly"
          :label="$t('contingency')"
        />
      </v-col>

      <v-col key="order_serial" cols="12" lg="1" md="2" sm="3">
        <form-field-text
          v-model="obInvoice.order_serial"
          :readonly="!obInvoice.is_contingency || readOnly"
          :required="obInvoice.is_contingency"
          label="serie"
        />
      </v-col>

      <v-col key="order_number" cols="12" lg="2" md="3" sm="4">
        <form-field-text
          v-model="obInvoice.order_number"
          :readonly="!obInvoice.is_contingency || readOnly"
          :required="obInvoice.is_contingency"
          label="number"
        />
      </v-col>

      <customer-row
        :customer-data="customerData"
        :read-only="readOnly"
        @open:dialog="onOpenDialog"
        @update:customer="onUpdateCustomerFirm"
        @change:customer="onSelectCustomer"
      />
      <!--      <v-col key="customer_id" cols="12" lg="3" md="4" sm="6">
        <customer-field-preview
          v-if="customer && signed"
          :item="customer"
          :not-load="!isReceived"
        />
        &lt;!&ndash;          :end-customer-on-init="!iMovementType || iMovementType !== 13"&ndash;&gt;
        <customer-select
          v-else-if="iMovementType"
          :create="!readOnly"
          :customer="isSalesLike"
          :disabled="readOnly"
          :foreign="iMovementType === 13"
          :provider="isPay"
          :require-dgi="requiredCustomerData"
          :value="customerId"
          required
          @change="onSelectCustomer"
        >
          <template #selection="{ item }">
            {{ sCustomerName ?? item }}
          </template>

          <template #update>
            <customer-form-dialog
              v-if="!readOnly"
              :require-dgi="requiredCustomerData"
              :value="customerData"
              basic
              firm-only
              @save="onUpdateCustomerFirm"
              @open:dialog="onOpenDialog"
            />
            <firm-preview-dialog v-else :item="customerFirm" />
          </template>
        </customer-select>
      </v-col>-->

      <v-col
        v-if="showCustomerBranches"
        key="customer_branch"
        cols="12"
        lg="3"
        md="4"
        sm="6"
      >
        <branch-select
          :customer-id="customerId"
          :disabled="readOnly"
          :value="obInvoice.customer_branch_id"
          clearable
          select
          @change="onSelectCustomerBranch"
          @clear="onClearCustomerBranch"
        />
      </v-col>

      <v-col
        key="created_at"
        :md="showPaymentTerms ? 3 : 4"
        cols="12"
        lg="3"
        sm="6"
      >
        <form-field-date-picker
          :disabled="readOnly"
          :max="maxCreatedAt"
          :min="minCreatedAt"
          :value="createdAt"
          required
          @change="onSetDate($event, 'created_at')"
        />
      </v-col>

      <v-col
        v-if="showPaymentTerms"
        key="payment_term_id"
        cols="12"
        lg="3"
        md="4"
        sm="6"
      >
        <payment-terms-select
          v-model="obInvoice.payment_term_id"
          :disabled="readOnly"
          :payment-terms="arCustomerPaymentTerms"
          :required="!readOnly"
          @change="onSelectPaymentTerm($event, true)"
        />
      </v-col>

      <v-col
        v-if="showDueDate"
        key="due_date"
        :md="showPaymentTerms ? 3 : 4"
        cols="12"
        lg="3"
        sm="6"
      >
        <form-field-date-picker
          :disabled="obInvoice.is_cash || !obPaymentTerm || readOnly"
          :max="maxDueDateAt"
          :min="minDueDateAt"
          :value="dueDateAt"
          label="due.date"
          required
          @change="onSetDate($event, 'due_date')"
        />
      </v-col>

      <v-col v-if="isRemito" key="transfer_type" cols="12" lg="3" md="4" sm="6">
        <invoice-transfer-type-select
          v-model="obInvoice.transfer_type"
          :disabled="readOnly"
        />
      </v-col>

      <template v-else>
        <v-col
          key="currency_id"
          :lg="rateGlobal ? 2 : 3"
          cols="12"
          md="4"
          sm="6"
        >
          <currency-select
            :disabled="!currencyIds.length || readOnly"
            :ids="currencyIds"
            :value="obInvoice.currency_id"
            autoassign
            company-only
            @change="onSelectCurrency"
          />
        </v-col>

        <v-col
          v-if="rateGlobal && obGlobalRate"
          key="rate_global"
          cols="12"
          lg="2"
          md="4"
          sm="6"
        >
          <form-field-text
            :value="sRateGlobal"
            input-type="text"
            label="rate.bcu"
            readonly
          >
            <template #append>
              <div><small v-text="obGlobalRate.currency.code" /></div>
              <!--              <v-chip small label>{{ obGlobalRate.currency.code }}</v-chip>-->
            </template>
          </form-field-text>
        </v-col>

        <v-col
          v-if="showCompanyCurrency"
          key="rate"
          cols="12"
          lg="2"
          md="4"
          sm="6"
        >
          <form-field-text
            :value="sRate"
            input-type="text"
            label="rate.company"
            readonly
            @input="fnDebounceRate"
          />
        </v-col>

        <v-col
          v-if="!isReceipt && !isDebt"
          key="price_list_id"
          cols="12"
          lg="3"
          md="4"
          sm="6"
        >
          <price-list-select
            :disabled="readOnly"
            :value="priceListId"
            autoselect
            @change="onChangePriceList"
          />
        </v-col>

        <v-col v-if="isSalesLike" key="vendor_collector" cols="12" lg="3">
          <vendor-collector-select
            :disabled="!customerId"
            v-model="vendorCollectorId"
          />
        </v-col>

        <v-col v-if="isSalesLike" key="warehouse" cols="12" lg="3">
          <warehouse-select v-model="warehouseId" />
        </v-col>

        <v-col
          v-if="isSalesLike"
          key="purchase_order"
          cols="12"
          lg="3"
          md="4"
          sm="6"
        >
          <form-field-text
            v-model="obInvoice.purchase_order_id"
            :disabled="readOnly"
            label="purchase.order"
          />
        </v-col>
      </template>

      <v-col v-if="isDebtRYA" key="price_limit" cols="12" lg="3" md="4" sm="6">
        <form-field-text
          v-model="fPriceLimit"
          :disabled="signed"
          input-type="number"
          label="amount.total"
        />
      </v-col>
    </v-row>
    <export-fields />
  </sheet>
</template>

<script lang="ts">
import type {
  Branch,
  BranchData,
  CompanySettingsData,
  CurrencyRate,
  CustomerData,
  FirmData,
  InvoiceGroupData,
  InvoiceMovementTypeData,
  PriceListData,
} from "@planetadeleste/vue-mc-gw";
import {
  CurrencyRates,
  Customer,
  InvoiceMovementType,
  InvoiceType,
  PaymentTerm,
} from "@planetadeleste/vue-mc-gw";
import { Component, Mixins, Prop } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import type { Currency, CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { EventBus } from "@/services/event-bus";
import type { DebounceFunction } from "@/plugins/helpers";
import { currencyFormat, trimLines } from "@/plugins/helpers";
import { ConfigModule } from "@/store/config";
import type { PaymentTermData } from "@planetadeleste/vue-mc-gw/src/types";
import dayjs from "dayjs";
import {
  assign,
  cloneDeep,
  debounce,
  forEach,
  get,
  isEmpty,
  isNil,
  isNumber,
  isObject,
  isPlainObject,
  map,
  omit,
  pick,
  set,
  toPlainObject,
  unset,
} from "lodash";

import InvoiceGroupSelect from "@/modules/invoices/components/InvoiceGroupSelect.vue";
import InvoiceMovementTypeSelect from "@/modules/invoices/components/InvoiceMovementTypeSelect.vue";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";
import BranchSelect from "@/modules/companies/components/BranchSelect.vue";
import CustomerSelect from "@/modules/customers/components/CustomerSelect.vue";
import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import PriceListSelect from "@/modules/pricelists/components/PriceListSelect.vue";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";
import CustomerFormDialog from "@/modules/customers/components/CustomerFormDialog.vue";
import FirmPreviewDialog from "@/modules/companies/components/FirmPreviewDialog.vue";
import PaymentTermsSelect from "@/modules/paymentterms/components/PaymentTermsSelect.vue";
import BtnCashCreditToggle from "@/modules/invoices/components/fields/BtnCashCreditToggle.vue";
import InvoiceTransferTypeSelect from "@/modules/invoices/components/InvoiceTransferTypeSelect.vue";
import type { InvoiceMovementTypeCode } from "@/types/utils";
import { number } from "mathjs";
import ExportFields from "@/modules/invoices/components/rows/ExportFields.vue";
import CustomerFieldPreview from "@/modules/customers/components/CustomerFieldPreview.vue";
import { InvoiceModule } from "@/store/invoice";
import CustomerRow from "@/modules/invoices/components/rows/CustomerRow.vue";
import VendorCollectorSelect from "@/modules/vendorcollectors/components/VendorCollectorSelect.vue";
import WarehouseSelect from "@/modules/warehouses/components/WarehouseSelect.vue";

@Component({
  components: {
    WarehouseSelect,
    VendorCollectorSelect,
    CustomerRow,
    CustomerFieldPreview,
    ExportFields,
    InvoiceTransferTypeSelect,
    BtnCashCreditToggle,
    BranchSelect,
    CompanyPreview,
    CurrencySelect,
    CustomerFormDialog,
    CustomerSelect,
    FirmPreviewDialog,
    FormFieldDatePicker,
    InvoiceGroupSelect,
    InvoiceMovementTypeSelect,
    PaymentTermsSelect,
    PriceListSelect,
  },
})
export default class InvoiceConfig extends Mixins(InvoiceMixin) {
  @Prop(Boolean) readonly readOnly!: boolean;
  @Prop(Boolean) readonly hideMovementTypeSelect!: boolean;

  obInvoiceGroup: InvoiceGroupData | null = null;
  fnDebounceRate!: DebounceFunction;
  customerData: Partial<CustomerData> = {};
  obPaymentTerm: PaymentTerm | null = null;
  obGlobalRate: CurrencyRate | null | undefined = null;

  get currencyIds(): number[] {
    return !!this.customer && this.customer.currencies
      ? map(this.customer.currencies, "id")
      : [];
  }

  get priceListId(): number {
    return this.obInvoice.get("price_list_id", 0);
  }

  set priceListId(iValue: number) {
    this.obInvoice.set("price_list_id", iValue);
  }

  get rate(): number | string | null {
    return this.obInvoice.get("rate");
  }

  set rate(iValue: number | string | null) {
    this.obInvoice.set("rate", iValue);
  }

  get sRate() {
    return this.rate && this.currency
      ? currencyFormat(number(this.rate), "UYU", 3, true)
      : 0;
  }

  get rateGlobal(): number | string {
    return this.obGlobalRate ? this.obGlobalRate.rate : 0;
  }

  get sRateGlobal() {
    return this.rateGlobal
      ? currencyFormat(number(this.rateGlobal), "UYU", 3, true)
      : 0;
  }

  get fPriceLimit(): number | undefined {
    return this.isDebtRYA
      ? number(this.obInvoice.get("price_limit", 0))
      : undefined;
  }

  set fPriceLimit(fValue: number | undefined) {
    this.obInvoice.set("price_limit", fValue);
  }

  get showCompanyCurrency() {
    return (
      this.currency &&
      this.currency.code !== "UYU" &&
      this.useCustomCurrencies &&
      this.rate
    );
  }

  get showCustomerBranches() {
    return (
      this.customer?.has_branches &&
      ((this.readOnly && this.customerId) || this.customerId)
    );
  }

  get items() {
    return this.positions;
  }

  get companySettings(): Partial<CompanySettingsData> {
    return this.company.get("settings", {});
  }

  get companyFirm(): Partial<FirmData> {
    return this.company.get("firm", {});
  }

  get useCustomCurrencies(): boolean {
    return this.companySettings
      ? (get(this.companySettings, "use_custom_currencies", false) as boolean)
      : false;
  }

  get arCustomerPaymentTerms(): Partial<PaymentTermData>[] {
    if (!this.customer) {
      return [];
    }

    return this.customer instanceof Customer
      ? this.customer.get("paymentterms", [])
      : get(this.customer, "paymentterms", []);
  }

  get sCustomerName() {
    return this.customerFirm?.dgi_denominacion || this.customerFirm?.name;
  }

  get obCreatedAt() {
    return this.obInvoice ? dayjs(this.obInvoice.get("created_at")) : dayjs();
  }

  get createdAt() {
    return this.obCreatedAt.format("YYYY-MM-DD");
  }

  get minCreatedAt(): string | undefined {
    if (!this.companyFirm) {
      return undefined;
    }
    const sDate = get(this.companyFirm, "dgi_local_updated_at");

    return sDate ? dayjs(sDate).format("YYYY-MM-DD") : undefined;
  }

  get maxCreatedAt(): string {
    return this.obCreatedAt.add(2, "month").format("YYYY-MM-DD");
  }

  get obDueDateAt(): dayjs.Dayjs | undefined {
    if (!this.obInvoice.due_date && this.signed) {
      return undefined;
    }

    return this.obInvoice && this.obInvoice.due_date
      ? dayjs(this.obInvoice.due_date)
      : this.obCreatedAt.clone();
  }

  get dueDateAt(): string | undefined {
    return this.obDueDateAt ? this.obDueDateAt.format("YYYY-MM-DD") : undefined;
  }

  set dueDateAt(sValue: string | undefined) {
    this.obInvoice.set("due_date", sValue);
  }

  get minDueDateAt() {
    let obDate = this.obCreatedAt.clone();

    if (
      this.obPaymentTerm &&
      this.obPaymentTerm.delay &&
      !this.obPaymentTerm.can_modify_date
    ) {
      obDate = obDate.add(
        this.obPaymentTerm.delay,
        this.obPaymentTerm.frequency
      );
    }

    return obDate.format("YYYY-MM-DD");
  }

  get maxDueDateAt(): undefined | string {
    if (this.obPaymentTerm && !this.obPaymentTerm.can_modify_date) {
      return this.minDueDateAt;
    }

    return undefined;
  }

  get isSale(): boolean {
    return this.sTypeCode !== InvoiceType.CODE_ERESGUARDO;
  }

  get isRemito(): boolean {
    return this.sTypeCode === InvoiceType.CODE_EREMITO;
  }

  /**
   * Get true if invoice is type "COBRANZA RYA"
   */
  get isDebtRYA(): boolean {
    return (
      this.iMovementType === InvoiceMovementType.CODE_DEBT_RYA &&
      this.sTypeCode === InvoiceType.CODE_ERECIBO
    );
  }

  /**
   * Get true if invoice movement is type "VENTA" OR "DEVOLUCION"
   *
   * @returns {boolean}
   */
  get isSalesLike(): boolean {
    return [
      InvoiceMovementType.CODE_SALES,
      InvoiceMovementType.CODE_REFOUND,
    ].includes(this.iMovementType);
  }

  /**
   * Display/hide cash/credit field selector
   *
   * @returns {boolean}
   */
  get showIsCash(): boolean {
    return (
      !this.signed &&
      [
        InvoiceMovementType.CODE_SALES,
        InvoiceMovementType.CODE_DEBIT_NOTE,
        InvoiceMovementType.CODE_REFOUND,
        InvoiceMovementType.CODE_BUY,
        // InvoiceMovementType.CODE_PAY,
        InvoiceMovementType.CODE_SALES_EXP,
      ].includes(this.iMovementType)
    );
  }

  /**
   * @returns {boolean} Show/hide payment terms select
   */
  get showPaymentTerms(): boolean {
    if (
      this.signed &&
      (!this.obInvoice.due_date || !this.obInvoice.payment_term_id)
    ) {
      return false;
    }

    return (
      !this.obInvoice.is_cash &&
      !!this.customer &&
      ![
        InvoiceMovementType.CODE_DEBT,
        InvoiceMovementType.CODE_DEBT_RYA,
      ].includes(this.iMovementType)
    );
  }

  /**
   * @returns {boolean} Show/hide due date picker
   */
  get showDueDate(): boolean {
    if (this.signed && !this.obInvoice.due_date) {
      return false;
    }

    return !this.obInvoice.is_cash && this.showPaymentTerms;
  }

  /**
   * Get true if invoice movement is type COBRANZA or ANULAR COBRANZA
   *
   * @returns {boolean}
   */
  get isDebt(): boolean {
    return [InvoiceMovementType.CODE_DEBT, 14, 15].includes(this.iMovementType);
  }

  /**
   * Get true if invoice movement is type RESGUARDO
   *
   * @returns {boolean}
   */
  get isReceipt(): boolean {
    return [
      InvoiceMovementType.CODE_RECEIPT,
      InvoiceMovementType.CODE_RECEIPT_CORRECT,
    ].includes(this.iMovementType);
  }

  get arMovementTypeCode(): InvoiceMovementTypeCode[] {
    return InvoiceModule.routedMovementTypeCode;

    /*const arItems: InvoiceMovementTypeCode[] = [];
    switch (this.$route.name as keyof RouteNamedMap) {
      case "invoices.received.view":
        arItems.push(InvoiceMovementType.CODE_BUY);
        arItems.push(InvoiceMovementType.CODE_PAY);
        break;

      default:
        // arItems.push(InvoiceMovementType.CODE_PAY);
        // arItems.push(InvoiceMovementType.CODE_BUY);
        arItems.push(InvoiceMovementType.CODE_SALES);
        arItems.push(InvoiceMovementType.CODE_REFOUND);
        arItems.push(InvoiceMovementType.CODE_DEBIT_NOTE);
        arItems.push(InvoiceMovementType.CODE_RECEIPT);
        arItems.push(InvoiceMovementType.CODE_DEBT);
        arItems.push(InvoiceMovementType.CODE_RECEIPT_CORRECT);
        arItems.push(InvoiceMovementType.CODE_SALES_EXP);
        arItems.push(14);
        arItems.push(15);
        break;
    }

    return arItems;*/
  }

  get cfeName() {
    if (!this.signed) {
      return "";
    }

    let sName = this.obInvoice.get("cfe_name", "");

    if (isNil(sName) || isEmpty(sName)) {
      sName = this.obInvoice.get("invoice_type.name", "");
    }

    return sName;
  }

  get vendorCollectorId() {
    return this.obInvoice.get("vendor_collector_id");
  }

  set vendorCollectorId(sValue: string | number) {
    this.obInvoice.set("vendor_collector_id", sValue);
  }

  get warehouseId() {
    return this.obInvoice.get("warehouse_id");
  }

  set warehouseId(sValue: string | number) {
    this.obInvoice.set("warehouse_id", sValue);
  }

  created() {
    this.fnDebounceRate = debounce(this.onChangeCurrency, 500);
  }

  mounted() {
    if (this.obInvoice.payment_term_id) {
      this.onSelectPaymentTerm(this.obInvoice.payment_term_id);
    }

    if (this.currency) {
      this.onSelectCurrency(this.currency, true);
    }
  }

  onSelectInvoiceGroup(obInvoiceGroup: InvoiceGroupData) {
    this.obInvoiceGroup = obInvoiceGroup;
  }

  onSelectMovementType(obModel: InvoiceMovementTypeData) {
    // (this.invoiceMovementType && this.invoiceMovementType.id === obModel.id)
    if (
      !obModel ||
      (this.invoiceMovementType && this.invoiceMovementType.id === obModel.id)
    ) {
      return;
    }

    // Reset invoice positions, payment_methods and references
    this.obInvoice.set({
      positions: [],
      references: [],
      payment_methods: [],
      customer: null,
      customer_id: null,
    });

    this.addEmptyItemPosition();
    this.obInvoice.sync();

    EventBus.emit("invoice.movement_type.changed", obModel);
  }

  async onSelectCustomer(obCustomer: Customer) {
    if (!obCustomer || isNil(obCustomer) || this.signed) {
      return;
    }

    if (obCustomer.id) {
      obCustomer = new Customer({ id: obCustomer.id });
      await obCustomer.fetch();
    }

    this.obInvoice.set("customer_id", obCustomer.id);
    this.obInvoice.set("customer", obCustomer);
    this.obInvoice.set("vendor_collector_id", obCustomer.vendor_collector_id);

    if (obCustomer.firm) {
      const obFirmData = obCustomer.firm;

      if (!obFirmData.dgi_denominacion && obFirmData.name) {
        obFirmData.dgi_denominacion = obFirmData.name;
      }

      const obCustomerFirmData = omit(obFirmData, ["id"]) as Partial<FirmData>;
      this.onSetCustomerFirmData(obCustomerFirmData);
    }

    if (get(obCustomer, "config.price_list_id")) {
      this.obInvoice.set(
        "price_list_id",
        get(obCustomer, "config.price_list_id")
      );
    }

    if (this.currency) {
      this.onSelectCurrency(this.currency, true);
    }

    EventBus.emit("invoice.customer.changed", obCustomer);
  }

  onSelectCustomerBranch(obData: Branch | BranchData) {
    const arKeys = [
      "address",
      "country_id",
      "state_id",
      "town_id",
      "state_text",
      "town_text",
      "dgi_dir_fiscal",
      "dgi_dpto_nom",
      "dgi_loc_nom",
      "dgi_local_sec_nro",
    ];
    const obFirmData = pick(obData.firm, arKeys);
    const obCustomerFirm = omit(this.customerFirm, arKeys);

    assign(obCustomerFirm, obFirmData);
    this.customerFirm = obCustomerFirm;
    this.obInvoice.set("customer_branch_id", obData.id);
  }

  onClearCustomerBranch() {
    if (!this.customer?.firm) {
      return;
    }

    const obFirmData = this.customer.firm;

    if (!obFirmData.dgi_denominacion && obFirmData.name) {
      obFirmData.dgi_denominacion = obFirmData.name;
    }

    this.obInvoice.set("customer_firm", omit(obFirmData, ["id"]));
  }

  cleanData<T = Record<string, any>>(obData: Record<string, any>): T {
    forEach(obData, (v, k) => {
      if (isObject(v)) {
        set(obData, k, this.cleanData(v));
      } else if (isNil(v)) {
        unset(obData, k);
      }
    });

    return obData as T;
  }

  onSetCustomerFirmData(obData: Partial<FirmData>) {
    const obFirmData = assign({}, this.customerData, omit(obData, ["id"]));
    this.obInvoice.set(
      "customer_firm",
      this.cleanData<Partial<FirmData>>(obFirmData)
    );
  }

  onOpenDialog() {
    const obFirmData = cloneDeep(toPlainObject(this.customerFirm));
    const obCustomerData =
      this.customer instanceof Customer
        ? this.customer.attributes
        : this.customer;

    set(obCustomerData, "firm", obFirmData);

    this.customerData = this.cleanData(obCustomerData);
  }

  onUpdateCustomerFirm(obData: Partial<CustomerData>) {
    if (!obData.firm) {
      return;
    }

    if (
      obData.firm.name !== obData.firm.dgi_denominacion &&
      this.customer.end_customer
    ) {
      obData.firm.dgi_denominacion = obData.firm.name;
    }

    this.onSetCustomerFirmData(obData.firm);
  }

  onChangeCurrency() {
    // eslint-disable-next-line prefer-rest-params
    const fRate = get(arguments, "0");

    this.obInvoice.set("rate", fRate);
    EventBus.emit("invoice.currency.rate.changed", [fRate, 0]);
  }

  onChangePriceList(obPriceList: PriceListData) {
    this.obInvoice.set("price_list_id", obPriceList.id);
    this.obInvoice.set("price_list", obPriceList);
    EventBus.emit("invoice.price_list.changed", obPriceList.id);
  }

  /**
   * Change invoice currency and emit event with new rate
   */
  async onSelectCurrency(obModel: Currency | CurrencyData, bForce = false) {
    if (!bForce && this.obInvoice.get("currency_id", 0) === obModel.id) {
      return;
    }

    let fRate = this.rate;
    let obCurrencyRate: CurrencyRate | undefined | null = null;

    this.obInvoice.set("currency_id", obModel.id);

    // If company doesn't use own currency rates, load BCU rate
    if (!this.useCustomCurrencies) {
      if (obModel.rate) {
        fRate = obModel.rate;
      }
    } else {
      const obCurrencyRateCollection = new CurrencyRates();
      // @ts-ignore
      await obCurrencyRateCollection
        .filterBy({
          company: this.company.id,
          currency: obModel.id,
          rated: 1,
          // ratedat: dayjs().format("YYYY-MM-DD"),
        })
        .fetch();

      if (obCurrencyRateCollection.length) {
        obCurrencyRate = obCurrencyRateCollection.first();
      }

      // Find for company currency rate
      if (!obCurrencyRate && ConfigModule.companyCurrencyRates.length) {
        obCurrencyRate = ConfigModule.companyCurrencyRates.find({
          currency_id: obModel.id,
        });
      }

      // Set round value to Invoice model
      if (obCurrencyRate) {
        let fRound = obCurrencyRate.get("round", 1);
        if (fRound === "0.00" || !fRound) {
          fRound = 1;
        }

        this.obInvoice.set("round", fRound);
      }

      // UYU currency is the main currency, if this is the selected one, rate value is set as null
      if (obModel.external_id) {
        // If not founded, try to load from currency id
        if (!obCurrencyRate) {
          const obCurrencyRates = new CurrencyRates();
          obCurrencyRates.page(1);
          // @ts-ignore
          await obCurrencyRates.filterBy({ currency: obModel.id }).fetch();

          if (obCurrencyRates.length) {
            obCurrencyRate = obCurrencyRates.first();
          }
        }

        fRate = obCurrencyRate ? obCurrencyRate.rate : null;
      }
    }

    this.obInvoice.set("rate", fRate);
    this.obInvoice.set("payment_methods", []);
    await this.onLoadGlobalCurrencyRate();
    EventBus.emit("invoice.currency.rate.changed", [this.rate, fRate]);
    EventBus.emit("invoice.change.price");
  }

  /**
   * Load BCU currency
   */
  async onLoadGlobalCurrencyRate() {
    if (!this.currency || !this.currency.external_id) {
      return;
    }

    const sDate = this.obCreatedAt.isAfter(dayjs())
      ? dayjs().format("YYYY-MM-DD")
      : this.createdAt;
    const obCurrencyRateCollection = new CurrencyRates();
    // @ts-ignore
    await obCurrencyRateCollection
      .filterBy({
        rated: 1,
        rated_at: sDate,
        currency: this.currency.id,
      })
      .fetch();

    this.obGlobalRate = obCurrencyRateCollection.first();
    if (this.rateGlobal && !this.useCustomCurrencies) {
      this.obInvoice.set("rate", this.rateGlobal);
    }
  }

  /**
   * Run on component mounted or on change payment term
   * Set value to payment_term_id, due_date_at and restrict edit access to due_date_at field
   */
  async onSelectPaymentTerm(
    sValue?: number | PaymentTerm | Partial<PaymentTermData>,
    ui?: boolean
  ) {
    // Replace old addenda setted by changed payment term
    if (
      this.obPaymentTerm &&
      this.obPaymentTerm.description &&
      !this.obInvoice.is_signed
    ) {
      let sAddenda: string = this.obInvoice.get("description");
      if (!isEmpty(sAddenda)) {
        sAddenda = sAddenda.replace(this.obPaymentTerm.description, "");
        this.obInvoice.set("description", trimLines(sAddenda));
      }
    }

    if (sValue) {
      this.obPaymentTerm = null;

      if (sValue instanceof PaymentTerm) {
        this.obPaymentTerm = sValue;
      } else if (isNumber(sValue)) {
        this.obPaymentTerm = new PaymentTerm({ id: sValue });
        await this.obPaymentTerm.fetch();
      } else if (isPlainObject(sValue)) {
        this.obPaymentTerm = new PaymentTerm(sValue);
      }
    }

    if (!this.obPaymentTerm) {
      return;
    }

    // Add addenda part of text to current invoice
    if (this.obPaymentTerm.description && !this.obInvoice.is_signed) {
      let sAddenda: string = this.obInvoice.get("description", "");
      if (!isEmpty(sAddenda)) {
        sAddenda += "\n";
      }
      sAddenda += this.obPaymentTerm.description;

      this.obInvoice.set("description", trimLines(sAddenda));
    }

    if (ui === true) {
      this.onSetDueDate();
    }
  }

  onSetDueDate() {
    if (this.readOnly || this.signed) {
      return;
    }

    let obDate = this.obCreatedAt.clone();

    if (this.obPaymentTerm && this.obPaymentTerm.delay) {
      obDate = obDate.add(
        this.obPaymentTerm.delay,
        this.obPaymentTerm.frequency
      );
    }

    this.obInvoice.set("due_date", obDate.format("YYYY-MM-DD"));
  }

  onSetDate(sValue: string, sKey: string) {
    this.obInvoice.set(sKey, sValue);

    if (sKey === "created_at") {
      this.onLoadGlobalCurrencyRate();
    }

    if (
      sKey === "created_at" &&
      !this.signed &&
      this.obDueDateAt &&
      this.obDueDateAt.isBefore(this.obCreatedAt)
    ) {
      this.obInvoice.set("due_date", this.createdAt);
    }
  }
}
</script>
